import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Switch from 'react-switch';
import { setPriceAlertEmail, togglePriceAlertHandler } from '../../../actions/baseActions';
import BaseTooltipError from '../Common/BaseTooltipError';

type EmailConfirmationProps = {
	email: string;
	isMobile: boolean;
	isDesktop: boolean;
	setPriceAlertEmail: (email: string) => void;
	togglePriceAlertHandler: (toggle: boolean) => void;
};
const EmailConfirmation: FC<EmailConfirmationProps> = (props) => {
	const { t } = useTranslation();
	const { isDesktop } = props;
	const [isChecked, setIsChecked] = useState(false);

	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		props.setPriceAlertEmail(e.target.value);
	};

	const handleChange = (checked: boolean) => {
		props.togglePriceAlertHandler(checked);
		setIsChecked(checked);
	};

	const getEmailInput = () => {
		return (
			<div className="position-relative">
				<div
					style={{
						position: 'absolute',
						top: '13px',
						left: '10px',
					}}
				>
					<img src="/images/email-o.svg" alt="" />
				</div>

				<input
					placeholder={t('priceAlert.emailPlaceholder')}
					autoComplete="email"
					name="email"
					onChange={onChange}
					value={props.email}
					type="email"
					className="form-control cs-input"
				/>
			</div>
		);
	};

	return (
		<>
			<div>
				<Switch
					checked={isChecked}
					onChange={handleChange}
					onColor="#f8e29e"
					onHandleColor="#F2C94C"
					handleDiameter={20}
					uncheckedIcon={false}
					checkedIcon={false}
					boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
					activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
					height={16}
					width={34}
					className="react-switch"
					id="material-switch"
				/>{' '}
				&nbsp;&nbsp;&nbsp;
				<span id="material-switch-label" onClick={() => handleChange(!isChecked)} className="cs-label">
					{t('priceAlert.label')}
				</span>
			</div>
			{!isDesktop && isChecked ? (
				<div className="col-12">
					<div className="row">
						<BaseTooltipError className="col-md-12" name="price-alert-email">
							{getEmailInput()}
						</BaseTooltipError>
					</div>
				</div>
			) : (
				''
			)}
		</>
	);
};

// @ts-ignore
const mapStateToProps = (state) => ({
	email: state.base.priceAlertEmail,
});
export default connect(mapStateToProps, { setPriceAlertEmail, togglePriceAlertHandler })(EmailConfirmation);
