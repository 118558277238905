import classnames from 'classnames';
import { format } from 'date-fns';
import { ChangeEvent, PureComponent } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
	setClosedPriceAlertSignUpModal,
	setPriceAlertEmail,
	togglePriceAlertSignUpModal,
} from '../../../actions/baseActions';
import { doSearchFlights, doSearchPartners } from '../../../actions/flightActions';
import Helper from '../../modules/Helper';
import { IAirportItem } from '../Common/AirportSelect/Item';
import Checkbox from '../Common/Checkbox';
import FadeTransition from '../Common/FadeTransition';

type ModalSignUpProps = {
	priceAlertShowModalSignUp: boolean;
	email: string;
	enabledVert: string;
	selectedFromLocation: IAirportItem;
	selectedFromDate?: Date;
	lng: string;
	setPriceAlertEmail: (email: string) => void;
	setClosedPriceAlertSignUpModal: (toggle: boolean) => void;
	doSearchFlights: () => void;
	doSearchPartners: (isKcForm: boolean) => void;
};
type ModalSignUpState = {
	show: boolean;
	isInvalidEmail: boolean;
	isCheckedNewsLetter: boolean;
};
class ModalSignUp extends PureComponent<ModalSignUpProps, ModalSignUpState> {
	constructor(props: ModalSignUpProps) {
		super(props);
		this.state = {
			show: false,
			isInvalidEmail: false,
			isCheckedNewsLetter: true,
		};
	}

	componentDidUpdate(prevProps: ModalSignUpProps) {
		if (prevProps.priceAlertShowModalSignUp !== this.props.priceAlertShowModalSignUp) {
			this.setState({
				show: this.props.priceAlertShowModalSignUp,
			});
		}
	}

	close = () => {
		this.setState({
			show: false,
		});
	};

	doSearch = () => {
		if (!Helper.isValidEmail(this.props.email) && this.props.email) {
			this.setState({
				isInvalidEmail: true,
			});
			return;
		}
		this.setState({
			isInvalidEmail: false,
		});
		this.close();
		this.props.setClosedPriceAlertSignUpModal(true);

		if (Helper.isKayakCompareFlowFront() || Helper.isKayakCompareFlowBack()) {
			const isKcForm = true;
			this.props.doSearchPartners(isKcForm);
			return;
		}

		this.props.doSearchFlights();
	};
	onChange = (e: ChangeEvent<HTMLInputElement>) => {
		this.props.setPriceAlertEmail(e.target.value);
	};

	componentDidMount() {
		const closeBtn = document.getElementsByClassName('close');
		if (closeBtn.length) {
			closeBtn[0].addEventListener('click', this.close);
		}
	}

	getSelectedLocation = () => {
		const { selectedFromLocation } = this.props;

		if (Helper.isEmpty(selectedFromLocation) || !this.state.show) {
			return;
		}
		return Helper.getSelectedLocation(selectedFromLocation, 200);
	};

	getCarsDataInfo = () => {
		const { selectedFromDate, lng } = this.props;

		return (
			<div className="data-info">
				<div className="circle-alert">
					<img src="/images/alert.svg" alt="" />
				</div>
				<div>
					<div className="location" id="price-alert-location-info">
						{this.getSelectedLocation()}
					</div>
					<div className="date">
						{format(selectedFromDate as Date, 'eee, MMM d, ', { locale: Helper.getLocaleByCode(lng) })}
					</div>
				</div>
			</div>
		);
	};

	render() {
		const { email, enabledVert } = this.props;
		return (
			<Modal
				backdrop={true}
				id="price-alert-modal-signup"
				size="sm"
				centered
				onEscapeKeyDown={this.close}
				onHide={this.doSearch}
				show={this.state.show}
			>
				<div onClick={() => this.doSearch()} id="exit-icon">
					<img src="/images/exit.svg" alt="" />
				</div>
				<Modal.Body>
					<h3 className="heading">{Helper.trans('priceAlert.modalSignUp.header')}</h3>
					<div className="title">{Helper.trans(`priceAlert.modalSignUp.${enabledVert}.title`)}</div>
					<div
						className={classnames('sign-up-form', enabledVert, {
							'mt-5': enabledVert === 'hotels',
						})}
					>
						{enabledVert === 'cars' ? this.getCarsDataInfo() : ''}

						<div className="form-group" style={{ position: 'relative' }}>
							<FadeTransition timeout={1003} in={this.state.isInvalidEmail}>
								<div className="tooltip-error">{Helper.trans('validation.priceAlert.email')}</div>
							</FadeTransition>
							<div style={{ position: 'relative' }}>
								<div style={{ position: 'absolute', left: '10px', top: '13px' }} className="icon">
									<img src="/images/email-icon.svg" alt="" />
								</div>
								<input
									onChange={this.onChange}
									value={email}
									autoComplete="email"
									placeholder={Helper.trans('keywords.emailAddress')}
									type="email"
									className="form-control"
								/>
							</div>
						</div>
						<div className="form-group">
							<button
								style={{ width: '100%' }}
								onClick={this.doSearch}
								className="btn btn-block btn-warning btn-yellow"
							>
								{Helper.trans('keywords.signUpAndSearch')}
							</button>
						</div>
					</div>
					<div>
						<Checkbox
							onChange={() => {
								this.setState({
									isCheckedNewsLetter: !this.state.isCheckedNewsLetter,
								});
							}}
							checked={this.state.isCheckedNewsLetter}
							label={Helper.trans('priceAlert.modalSignUp.emailNewsletterSubscription')}
							id="price-alert-news-letter"
						/>
					</div>
					<br />
					<div>
						<p className="desc">{Helper.trans('priceAlert.modalSignUp.bottomDescription')}</p>
					</div>
				</Modal.Body>
			</Modal>
		);
	}
}

// @ts-ignore
const mapStateToProps = (state) => ({
	priceAlertShowModalSignUp: state.base.priceAlertShowModalSignUp,
	email: state.base.priceAlertEmail,
	selectedFromLocation: state.flight.selectedFromLocation,
	selectedFromDate: state.flight.selectedFromDate,
	enabledVert: state.base.enabledVert,
	lng: state.base.lng,
});
export default connect(mapStateToProps, {
	togglePriceAlertSignUpModal,
	setPriceAlertEmail,
	doSearchFlights,
	doSearchPartners,
	setClosedPriceAlertSignUpModal,
})(ModalSignUp);
