import classnames from 'classnames';
import dynamic from 'next/dynamic';
import { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { setTooltipError } from '../../../actions/baseActions';
import { doSearchFlights, prePopulateDataFromQueryString } from '../../../actions/flightActions';
import i18n from '../../locales/i18n';
import Helper from '../../modules/Helper';
import { ItemType } from '../Common/InputDropDown';
import TooltipError from '../Common/TooltipError';
import Trans from '../Common/Trans';
import AirportSelectFrom from '../Flight/AirportSelectFrom';
import AirportSelectRevers from '../Flight/AirportSelectRevers';
import AirportSelectTo from '../Flight/AirportSelectTo';
import TripTypes from '../Flight/BaseInputs/TripTypes';
import DatePickerFrom from '../Flight/DatePickerFrom';
import DatePickerTo from '../Flight/DatePickerTo';
import InputPassengers from '../Flight/InputPassengers';
import InputPricingTypes from '../Flight/InputPricingTypes';
import PricingTypes from '../Flight/PricingTypes';
import Confirmation from '../PriceAlert/Confirmation';
import ModalSignUp from '../PriceAlert/ModalSignUp';
import PassengerCounter from './FilterForm/PassengerCounter';

const PriceAlertCheckboxConfirmation = dynamic(() => import('../PriceAlert/PriceAlertCheckboxConfirmation'), {
	ssr: false,
});
interface FilterFormProps {
	priceAlertHandlerChecked: boolean;
	openedInput: string;
	compare?: boolean;
	selectedTripType: ItemType;
	isUserLoggedIn: boolean;
	isSigninCheckboxChecked: boolean;
	prePopulateDataFromQueryString: VoidFunction;
	doSearchFlights: (compare?: boolean) => void;
}

const FilterForm: FC<FilterFormProps> = (props) => {
	const isOneWay = props.selectedTripType.value === 'one-way';

	const { isUserLoggedIn, isSigninCheckboxChecked } = props;
	const showNewsletterCriteria = !(isUserLoggedIn && isSigninCheckboxChecked);

	const checkScroll = () => {
		const w = window.innerWidth;

		if (props.openedInput && ['airport-from', 'airport-to'].includes(props.openedInput) && w < 575) {
			document.documentElement.scrollTop = 1;
			setTimeout(() => {
				window.scrollTo(0, 1);
			}, 150);
		}
	};

	useEffect(() => {
		props.prePopulateDataFromQueryString();
		if (document !== undefined) {
			document.addEventListener('scroll', checkScroll);
		}
		return () => {
			document.removeEventListener('scroll', checkScroll);
		};
	}, []);

	return (
		<>
			<form id="redirect-back-form" method="post" action={process.env.NEXT_PUBLIC_BACKEND_URL + '/flights/redirect'} />
			<div id="filter-form">
				<div className="inline-inputs" id="base-inputs">
					<TripTypes />
					<div className="input d-none d-xl-block">
						<PricingTypes />
					</div>
					<div className="input d-none d-xl-block">
						<PassengerCounter name="passengers-counter" placeholder={Helper.trans('keywords.passengers')} />
					</div>
				</div>
				<div className="row">
					<div className="col pr-lg-1">
						<div className="row main-inputs">
							<div
								className={classnames('col-xl-3 col-md-6 ', {
									'col-12 col-md-12 col-xl-4': isOneWay,
								})}
							>
								<TooltipError
									labelClass="d-block d-xl-none"
									label={Helper.trans('keywords.from')}
									flexOne
									name="airport-from"
								>
									<AirportSelectFrom name="airport-from" />
								</TooltipError>
							</div>
							<div className="col-1 d-xl-block d-none" style={{ maxWidth: '62px' }}>
								<TooltipError name="airport-reverse">
									<AirportSelectRevers />
								</TooltipError>
							</div>
							<div
								className={classnames('col-xl-3 col-md-6 ', {
									'col-md-12 col-xl-4': isOneWay,
								})}
							>
								<TooltipError
									labelClass="d-block d-xl-none"
									label={Helper.trans('keywords.to')}
									flexOne
									name="airport-to"
								>
									<AirportSelectTo name="airport-to" />
								</TooltipError>
							</div>
							<div
								className={classnames('flex-xl-grow-1 col-xl-2 col-6', {
									'col-12': isOneWay,
								})}
							>
								<TooltipError labelClass="d-block d-xl-none" label={i18n.t('keywords.departing')} name="date-from">
									<DatePickerFrom name="date-from" />
								</TooltipError>
							</div>

							{props.selectedTripType.value !== 'one-way' ? (
								<div className="flex-xl-grow-1 col-xl-2 col-lg-6 col-6">
									<TooltipError labelClass="d-block d-xl-none" label={i18n.t('keywords.returning')} name="date-to">
										<DatePickerTo name="date-to" />
									</TooltipError>
								</div>
							) : (
								''
							)}
						</div>
						<div className="row main-inputs d-xl-none">
							<div className="d-xl-none  col-6">
								<TooltipError
									labelClass="d-block d-xl-none"
									label={i18n.t('keywords.class')}
									name="input-pricing-types"
								>
									<InputPricingTypes name="input-pricing-types" />
								</TooltipError>
							</div>

							<div className="d-xl-none col-6">
								<TooltipError
									labelClass="d-block d-xl-none"
									label={i18n.t('keywords.travelers')}
									name="mobile-input-passengers"
								>
									<InputPassengers name="mobile-input-passengers" />
								</TooltipError>
							</div>
						</div>
					</div>
					{showNewsletterCriteria && (
						<div className="d-xl-none d-block my-2">
							<Confirmation onlyMobile showCheckbox />
						</div>
					)}

					<div className="col-xs-3 col-md-12 col-xl-2 ">
						<div className="input-items ">
							<div id="search-button-container">
								<button
									onClick={() => props.doSearchFlights(props.compare)}
									id="search-button"
									type="button"
									className="btn btn-warning"
								>
									<Trans i18nKey="keywords.search" />
								</button>
							</div>
						</div>
					</div>
				</div>
				{showNewsletterCriteria && (
					<div className="row">
						<div className="col-xl col-md-12 " style={{ marginRight: '4px' }}>
							<div style={{ width: '100%' }} className="mt-2 d-flex justify-content-between align-items-center">
								<div />
								<div className="d-xl-block d-none">
									<PriceAlertCheckboxConfirmation />
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
			{Helper.isDesktop() && <ModalSignUp />}
		</>
	);
};

const mapStateToProps = (state: any) => ({
	selectedTripType: state.flight.selectedTripType,
	openedInput: state.base.openedInput,
	priceAlertHandlerChecked: state.base.priceAlertHandlerChecked,
	isSigninCheckboxChecked: state.base.signinCheckbox,
	isUserLoggedIn: state.base.isUserLoggedIn,
});

const mapDispatchToProps = {
	setTooltipError,
	doSearchFlights,
	prePopulateDataFromQueryString,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterForm);
