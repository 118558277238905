import Image from 'next/image';
import React from 'react';
import { connect } from 'react-redux';
import { jumpToSearchInput } from '../../../actions/baseActions';

export interface ICompanyImageProps {
	src: string;
	jumpToInputName?: string;
	jumpToSearchInput: (name?: string) => void;
}

const CompanyImage: React.FC<ICompanyImageProps> = (props) => {
	return (
		<div
			onClick={() => props.jumpToSearchInput(props.jumpToInputName)}
			style={{ position: 'relative', overflow: 'hidden' }}
		>
			<Image
				width={130}
				height={40}
				src={props.src}
				alt={props.src.split('/')[3].split('.')[0]}
				style={{ objectFit: 'contain' }}
			/>
		</div>
	);
};

export default connect(null, { jumpToSearchInput })(CompanyImage);
