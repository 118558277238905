import { ChangeEvent, FC, useState } from 'react';
import { connect } from 'react-redux';
import { setPriceAlertEmail, togglePriceAlertHandler } from '../../../actions/baseActions';
import BaseTooltipError from '../Common/BaseTooltipError';
// @ts-ignore
import { useTranslation } from 'react-i18next';
import Checkbox from '../Common/Checkbox';

type ConfirmationProps = {
	setPriceAlertEmail: (email: string) => void;
	togglePriceAlertHandler: (toggle: boolean) => void;
	email: string;
	isMobile: boolean;
	isDesktop: boolean;
};

const EmailConfirmationCheckbox: FC<ConfirmationProps> = (props) => {
	const { t } = useTranslation();
	const { isDesktop } = props;
	const [isChecked, setIsChecked] = useState(false);

	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		props.setPriceAlertEmail(e.target.value);
	};
	const handleChange = (checked: boolean) => {
		props.togglePriceAlertHandler(checked);
		setIsChecked(checked);
	};
	const getEmailInput = () => {
		return (
			<div className="position-relative">
				<div
					style={{
						position: 'absolute',
						top: '13px',
						left: '10px',
					}}
				>
					<img src="/images/email-o.svg" alt="" />
				</div>

				<input
					placeholder={t('priceAlert.emailPlaceholder')}
					autoComplete="email"
					name="email"
					onChange={onChange}
					value={props.email}
					type="email"
					className="form-control cs-input"
				/>
			</div>
		);
	};

	return (
		<>
			<Checkbox
				checked={isChecked}
				label={t('priceAlert.label')}
				id="price-alert-email-signup"
				onChange={() => handleChange(!isChecked)}
			/>
			{!isDesktop && isChecked ? (
				<div className="col-12">
					<div className="row">
						<BaseTooltipError className="col-md-12" name="price-alert-email">
							{getEmailInput()}
						</BaseTooltipError>
					</div>
				</div>
			) : (
				''
			)}
		</>
	);
};

// @ts-ignore
const mapStateToProps = (state) => ({
	email: state.base.priceAlertEmail,
});
export default connect(mapStateToProps, { setPriceAlertEmail, togglePriceAlertHandler })(EmailConfirmationCheckbox);
