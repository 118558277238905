import React from 'react';
import { connect } from 'react-redux';
import Helper from '../../modules/Helper';
import EmailConfirmation from './EmailConfirmation';
import EmailConfirmationCheckbox from './EmailConfirmationCheckbox';

type ConfirmationProps = {
	onlyMobile?: boolean;
	showCheckbox?: boolean;
	isBrowserSupportNotification?: boolean;
	hideOnMobile?: boolean;
	onlyDesktop?: boolean;
	priceAlert: string[];
};
type ConfirmationState = {
	isMobile: boolean;
};
class Confirmation extends React.Component<ConfirmationProps, ConfirmationState> {
	constructor(props: ConfirmationProps) {
		super(props);
		this.state = {
			isMobile: false,
		};
		if (typeof window != 'undefined') {
			window.addEventListener('resize', this.checkWindowSize);
		}
	}

	componentDidMount() {
		if (typeof window != 'undefined') {
			this.checkWindowSize();
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.checkWindowSize);
	}

	checkWindowSize = () => {
		// @ts-ignore
		const width = parseInt(window.innerWidth);
		this.setState({
			isMobile: width < 1200,
		});
	};

	render() {
		if (typeof window == 'undefined') {
			return <></>;
		}

		const isMobile = this.state.isMobile;
		const isDesktop = !isMobile;
		if (this.props.onlyDesktop && isMobile) {
			return <></>;
		}
		if (this.props.onlyMobile && isDesktop) {
			return <></>;
		}
		if (this.props.hideOnMobile && isMobile) {
			return <></>;
		}

		const REGISTER_PRICE_ALERTS = Helper.bool(process.env.NEXT_PUBLIC_REGISTER_PRICE_ALERTS as string);
		if (!REGISTER_PRICE_ALERTS) {
			return <></>;
		}
		const priceAlert = this.props.priceAlert;
		if (priceAlert.indexOf('email') !== -1) {
			if (this.props.showCheckbox) {
				return <EmailConfirmationCheckbox isDesktop={isDesktop} isMobile={isMobile} />;
			}
			return <EmailConfirmation isDesktop={isDesktop} isMobile={isMobile} />;
		}
		return <></>;
	}
}

// @ts-ignore
const mapStateToProps = (state) => ({
	priceAlert: state.base.priceAlert,
	isBrowserSupportNotification: state.base.isBrowserSupportNotification,
	random: state.base.random,
});
export default connect(mapStateToProps, {})(Confirmation);
