import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { selectPricingType } from '../../../actions/flightActions';
import InputDropDown, { ItemType } from '../Common/InputDropDown';
import { pricingTypes } from './data';

type InputPricingTypesProps = {
	selectPricingType: (item: ItemType) => void;
	selectedPricingType?: ItemType;
	name: string;
};

const InputPricingTypes: FC<InputPricingTypesProps> = (props) => {
	const { t } = useTranslation();

	const onSelect = (item: ItemType) => {
		props.selectPricingType(item);
	};

	return (
		<InputDropDown
			onSelect={onSelect}
			name={props.name}
			selected={props.selectedPricingType}
			placeholder={t('keywords.pricingType')}
			items={pricingTypes}
		/>
	);
};

// @ts-ignore
const mapStateToProps = (state) => ({
	selectedPricingType: state.flight.selectedPricingType,
});

export default connect(mapStateToProps, { selectPricingType })(InputPricingTypes);
