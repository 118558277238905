import { FC } from 'react';
import { connect } from 'react-redux';
import { selectPricingType } from '../../../actions/flightActions';
import Helper from '../../modules/Helper';
import BasicDropDown from '../Common/BasicDropDown';
import { ItemType } from '../Common/InputDropDown';
import { pricingTypes } from './data';

type PricingTypesProps = {
	selectedPricingType?: ItemType;
	selectPricingType: (item: ItemType) => void;
};

const PricingTypes: FC<PricingTypesProps> = (props) => {
	const onSelect = (item: ItemType) => {
		props.selectPricingType(item);
	};

	return (
		<BasicDropDown
			onSelect={onSelect}
			name="pricing-type"
			selected={props.selectedPricingType}
			placeholder={Helper.trans('keywords.pricingType')}
			items={pricingTypes}
			testId="pricing-types"
		/>
	);
};

const mapStateToProps = (state: any) => ({
	selectedPricingType: state.flight.selectedPricingType,
});

export default connect(mapStateToProps, { selectPricingType })(PricingTypes);
