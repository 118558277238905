import classnames from 'classnames';
import React from 'react';
import Helper from '../../../modules/Helper';

export interface IItemProps {
	keyword: string;
	type?: string;
	enabledVert: string;
	focused?: boolean;
	firstItem?: boolean;
	item: IAirportItem;
	onHover: () => void;
	onHoverClose: () => void;
	onSelect: (selectedItem: IAirportItem) => void;
}

export interface IAirportItem {
	name: string;
	allAirports?: boolean;
	iata?: string;
	type?: string;
	city?: string;
	state?: string;
	country: string;
	documentId: string;
}

const Item: React.FC<IItemProps> = (props) => {
	const replaceKeywordWithHighlight = (value: string, keyword: string): string => {
		return Helper.replaceAll(value, keyword + '(?![^<>]*>)', (match: string) => {
			return `<span class="highlight">${match}</span>`;
		});
	};
	const searchKeyword = (value: string) => {
		const keyword = props.keyword
			.replace(/[()]/g, '')
			.replace(/\s/g, ',')
			.split(',')
			.filter((k) => k.length > 1);

		for (let i = 0; i < keyword.length; i++) {
			let search = keyword[i];
			search = Helper.capitalize(search.trim());

			if (value !== undefined) {
				const currentLength = value.length;
				value = replaceKeywordWithHighlight(value, search);
				const newLength = value.length;

				if (newLength === currentLength) {
					// start replacing vowels characters
					const vowelsCharacters = ['a', 'e', 'i', 'o', 'u'];
					let founded = false;

					for (let i = 0; i < vowelsCharacters.length; i++) {
						if (founded) {
							break;
						}

						for (let j = 0; j < vowelsCharacters.length; j++) {
							const newSearch = search.replace(vowelsCharacters[i], vowelsCharacters[j]);
							value = replaceKeywordWithHighlight(value, newSearch);

							if (value.length !== currentLength) {
								// Found the word
								founded = true;
								break;
							}
						}
					}
				}
			}
		}
		return value;
	};

	let { name, iata, city, country, state } = props.item;
	const enabledVert = props.enabledVert;
	if (iata) {
		name += ' (' + iata + ')';
	}
	if (props.firstItem) {
		name = Helper.trans('keywords.allAirports');
		if (iata) {
			name += searchKeyword(' (' + iata + ')');
		}
	} else {
		name = searchKeyword(name);
	}
	let isCity = false;
	if (enabledVert === 'cars' && !iata) {
		isCity = true;
	}
	let type = props.type;
	if (isCity) {
		type = 'city';
	}

	const info = [];
	if (city) {
		info.push(searchKeyword(city));
	} else if (state) {
		info.push(searchKeyword(state));
	}
	country = searchKeyword(country);
	info.push(country);

	return (
		<div
			onClick={() => props.onSelect(props.item)}
			className={classnames('item ' + type, {
				focused: props.focused,
			})}
			onMouseOver={props.onHover}
			onMouseOut={props.onHoverClose}
		>
			<div>
				<div
					dangerouslySetInnerHTML={{
						__html: name,
					}}
					className="name"
				/>
				<div
					dangerouslySetInnerHTML={{
						__html: info.join(', '),
					}}
					className="info"
				/>
			</div>
		</div>
	);
};

// @ts-ignore
const areEqual = (prevProps, nextProps) => {
	return Helper.shallowEqual(prevProps.item, nextProps.item) && nextProps.keyword === prevProps.keyword;
};

export default React.memo(Item, areEqual);
