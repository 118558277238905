import { addDays, format, isAfter, subDays } from 'date-fns';
import React, { RefObject } from 'react';
import { DateFormatter, DateRange, DayPicker, DayPickerBase, addToRange } from 'react-day-picker';
import { connect } from 'react-redux';
import { togglePreventEnter } from '../../../actions/baseActions';

import '@styles/DatePicker.scss';
import Helper from 'app/modules/Helper';

type DatePickerProps = {
	lng: string;
	before: Date;
	selected: Date;
	className?: string;
	showErrors?: boolean;
	togglePreventEnter: (toggle: boolean) => void;
	onSelectDate: (day: Date, toggleKeyboardNavigation?: boolean) => void;
};
type DatePickerState = {
	from: Date | undefined | null;
	to: Date | undefined | null;
};

class DatePicker extends React.Component<DatePickerProps, DatePickerState> {
	calendarRef: RefObject<DayPickerBase>;

	constructor(props: DatePickerProps) {
		super(props);
		this.state = this.getInitialState();
		this.calendarRef = React.createRef();
	}

	NU_LOCALE = 'en-u-nu-latn';

	getInitialState = () => {
		return {
			from: undefined,
			to: undefined,
		};
	};

	handleDayClick = (day: Date) => {
		const range = addToRange(day, this.state as DateRange);
		this.setState(range as DatePickerState);
	};

	handleResetClick = () => {
		this.setState(this.getInitialState());
	};

	componentDidMount() {
		document.addEventListener('keydown', this.onKeyUp);
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.onKeyUp);
	}

	onKeyUp = (e: KeyboardEvent) => {
		const { selected, before } = this.props;
		if ([32, 37, 38, 39, 40, 13].indexOf(e.keyCode) > -1) {
			e.stopPropagation();
			e.preventDefault();
		}

		switch (e.key) {
			// Up
			case 'ArrowUp':
				const sevenDaysBefore = subDays(selected, 7);
				if (isAfter(sevenDaysBefore, subDays(before, 1))) {
					this.selectDateNavigation(sevenDaysBefore, true);
				}
				break;
			// Down
			case 'ArrowDown':
				const sevenDaysAfter = addDays(selected, 7);
				this.selectDateNavigation(sevenDaysAfter, true);
				break;
			// Left
			case 'ArrowLeft':
				const oneDaysBefore = subDays(selected, 1);
				if (isAfter(oneDaysBefore, subDays(before, 1))) {
					this.selectDateNavigation(oneDaysBefore, true);
				}
				break;
			// Right
			case 'ArrowRight':
				const oneDaysAfter = addDays(selected, 1);
				this.selectDateNavigation(oneDaysAfter, true);
				break;
			// Enter
			case 'Enter':
				this.props.togglePreventEnter(true);
				this.selectDateNavigation(selected, false);
				break;
			default:
				break;
		}
		return false;
	};
	selectDateNavigation = (date: Date, preventClose: boolean) => {
		this.props.onSelectDate(date, preventClose);
	};

	render() {
		const from = this.props.selected;
		const { before, lng, onSelectDate } = this.props;
		const modifiers = {
			start: from,
		};

		const formatCaption: DateFormatter = (date, options) => {
			const m = format(date, 'LLLL', { locale: options?.locale });
			const y = date.getFullYear().toLocaleString(this.NU_LOCALE, { useGrouping: false });

			return `${m} ${y}`;
		};
		const formatWeekdayName: DateFormatter = (date, options) => {
			const m = format(date, 'EEE', { locale: options?.locale });
			return m;
		};
		return (
			<div className="date-picker-range">
				<DayPicker
					locale={Helper.getLocaleByCode(lng)}
					defaultMonth={from}
					showOutsideDays={true}
					fromMonth={before}
					onDayClick={(date: Date) => onSelectDate(date)}
					numberOfMonths={1}
					disabled={{ before }}
					selected={from}
					modifiers={modifiers}
					formatters={{ formatCaption, formatWeekdayName }}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	lng: state.base.lng,
});
export default connect(mapStateToProps, { togglePreventEnter })(DatePicker);
