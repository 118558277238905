import classNames from 'classnames';
import { FC } from 'react';

type CounterItemProps = {
	name: string;
	count: number;
	onCountChange: (count: number) => void;
};
const CounterItem: FC<CounterItemProps> = ({ count, onCountChange, name }) => {
	const decrementCount = () => {
		onCountChange(count - 1);
	};

	const incrementCount = () => {
		onCountChange(count + 1);
	};
	return (
		<div className="item counter">
			<div className="name">{name}</div>
			<div className="counter-control">
				<div onClick={decrementCount} className="subtract">
					-
				</div>
				<div className="count">{count}</div>
				<div onClick={incrementCount} className={classNames('add', { disabled: count === 9 })}>
					<span>+</span>
				</div>
			</div>
		</div>
	);
};
export default CounterItem;
