import { AxiosResponse } from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { openInput, setLocationByIATACode } from '../../../actions/baseActions';
import { selectFromLocation } from '../../../actions/flightActions';
import { FLIGHT_SELECT_FROM_LOCATION } from '../../../actions/types';
import ApiInterface from '../../modules/ApiInterface';
import Helper from '../../modules/Helper';
import AirportSelect from '../Common/AirportSelect';
import { IAirportItem } from '../Common/AirportSelect/Item';

import AirplaneIcon from '@images/flights/airplan-right.svg';

type AirportSelectFromProps = {
	name: string;
	selectedFromLocation?: IAirportItem;
	autoJump?: boolean;
	preventFocus?: boolean;
	preventSetInputBoxClassName?: boolean;
	selectFromLocation: (item: IAirportItem | object) => void;
	openInput: (name?: string | null) => void;
	setLocationByIATACode: (location: string, type: string) => void;
};
type AirportSelectFromState = {
	checkedDestCode: boolean;
};

class AirportSelectFrom extends React.Component<AirportSelectFromProps, AirportSelectFromState> {
	constructor(props: AirportSelectFromProps) {
		super(props);
		this.state = {
			checkedDestCode: false,
		};
	}

	onChange = (keyword: string) => {
		if(!keyword.trim().length) {
			this.props.selectFromLocation({});
		}
	}

	onSelect = (location: IAirportItem | object) => {
		this.props.selectFromLocation(location);

		if (this.props.autoJump) {
			if (Helper.isMobile()) {
				this.props.openInput('');

				setTimeout(() => {
					this.props.openInput('airport-to');
				}, 1200);

				return;
			}
			this.props.openInput('airport-to');
		} else if (!this.props.preventFocus) {
			this.props.openInput();
		}
	};

	componentDidMount() {
		setTimeout(() => {
			const parsed = Helper.parseQueryString(window.location.search, true);
			const origcode = parsed.origcode as string;

			if (Helper.isEmpty(this.props.selectedFromLocation) && !Helper.isMobile()) {
				const input = document.getElementById(this.props.name + '-input') as HTMLInputElement;
				input.focus();
				input.select();
			}

			if (origcode && origcode.length === 3) {
				this.props.setLocationByIATACode(origcode.toUpperCase(), FLIGHT_SELECT_FROM_LOCATION);
			}
		}, 1);
	}

	componentDidUpdate(prevProps: AirportSelectFromProps) {
		if (
			prevProps.selectedFromLocation !== this.props.selectedFromLocation &&
			!Helper.isEmpty(this.props.selectedFromLocation)
		) {
			(document.getElementById(this.props.name + '-input') as HTMLInputElement).disabled = true;
			(document.getElementById(this.props.name + '-input') as HTMLInputElement).disabled = false;
		}

		const parsed = Helper.parseQueryString(window.location.search, true);

		if (this.state.checkedDestCode) {
			return;
		}

		this.setState(
			{
				checkedDestCode: true,
			},
			() => {
				const input = document.getElementById('airport-to-input') as HTMLInputElement;
				if (parsed.destcode && parsed.destcode.length === 3) {
					ApiInterface.instance
						.fetchAirport(parsed.destcode)
						.then((res: AxiosResponse) => {
							if (Helper.isEmpty(res.data) && !this.props.preventFocus) {
								input.focus();
								input.select();
							}
						})
						.catch(() => {});
				} else if (!Helper.isEmpty(this.props.selectedFromLocation) && !this.props.preventFocus && !Helper.isMobile()) {
					input.focus();
					input.select();
				}
			}
		);
	}

	render() {
		const placeholder = Helper.trans('main.flights.fromInputPlaceholder');

		return (
			<AirportSelect
				mobileHeading={placeholder}
				i18nValidationDropDownKey="validation.flights.fromLocationSelectDropDown"
				preventSetInputBoxClassName={this.props.preventSetInputBoxClassName}
				icon={AirplaneIcon}
				placeholder={placeholder}
				selected={this.props.selectedFromLocation}
				name={this.props.name}
				onSelect={this.onSelect}
				onChange={this.onChange}
			/>
		);
	}
}

const mapStateToProps = (state: any) => ({
	selectedFromLocation: state.flight.selectedFromLocation,
	autoJump: state.base.autoJump,
	preventFocus: state.base.preventFocus,
});

export default connect(mapStateToProps, { selectFromLocation, openInput, setLocationByIATACode })(AirportSelectFrom);
