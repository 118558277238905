import React from 'react';
import { connect } from 'react-redux';
import { openInput, setLocationByIATACode } from '../../../actions/baseActions';
import { selectToLocation } from '../../../actions/flightActions';
import { FLIGHT_SELECT_TO_LOCATION } from '../../../actions/types';
import Helper from '../../modules/Helper';
import AirportSelect from '../Common/AirportSelect';
import { IAirportItem } from '../Common/AirportSelect/Item';

import AirplaneIcon from '@images/flights/airplane-left.svg';

type AirportSelectToProps = {
	name: string;
	selectedToLocation?: IAirportItem;
	autoJump?: boolean;
	preventSetInputBoxClassName?: boolean;
	placeholder?: string;
	selectToLocation: (item: IAirportItem | object) => void;
	openInput: (name?: string | null) => void;
	setLocationByIATACode: (location: string, type: string) => void;
};

class AirportSelectTo extends React.Component<AirportSelectToProps> {
	onSelect = (location: IAirportItem | object) => {
		this.props.selectToLocation(location);
		if (this.props.autoJump) {
			this.props.openInput('date-from');
		} else {
			this.props.openInput();
		}
	}

	onChange = (keyword: string) => {
		if(!keyword.trim().length) {
			this.props.selectToLocation({});
		}
	}

	componentDidMount() {
		const parsed = Helper.parseQueryString(window.location.search, true);
		const destcode = parsed.destcode as string;
		if (destcode && destcode.length === 3) {
			this.props.setLocationByIATACode(destcode.toUpperCase(), FLIGHT_SELECT_TO_LOCATION);
		}
	}

	componentDidUpdate(prevProps: AirportSelectToProps) {
		if (
			prevProps.selectedToLocation !== this.props.selectedToLocation &&
			!Helper.isEmpty(this.props.selectedToLocation)
		) {
			setTimeout(() => {
				document.getElementById(this.props.name + '-input')?.blur();
			}, 1);
		}
	}

	render() {
		const name = this.props.name;
		const placeholder = this.props.placeholder || Helper.trans('main.flights.toInputPlaceholder');

		return (
			<AirportSelect
				mobileHeading={placeholder}
				i18nValidationDropDownKey="validation.flights.toLocationSelectDropDown"
				preventSetInputBoxClassName={this.props.preventSetInputBoxClassName}
				icon={AirplaneIcon}
				placeholder={placeholder}
				selected={this.props.selectedToLocation}
				name={name}
				onSelect={this.onSelect}
				onChange={this.onChange}
			/>
		);
	}
}

// @ts-ignore
const mapStateToProps = (state) => ({
	selectedToLocation: state.flight.selectedToLocation,
	selectedFromLocation: state.flight.selectedFromLocation,
	autoJump: state.base.autoJump,
});

export default connect(mapStateToProps, { selectToLocation, openInput, setLocationByIATACode })(AirportSelectTo);
