import ExitIcon from '@images/exit.svg';
import axios, { AxiosRequestConfig } from 'axios';
import classnames from 'classnames';
import { debounce } from 'lodash';
import React from 'react';
import CustomScroll from 'react-custom-scroll';
import 'react-custom-scroll/dist/customScroll.css';
import { connect } from 'react-redux';
import { openInput, setTooltipError, togglePreventEnter, togglePreventFocus } from '../../../actions/baseActions';
import i18n from '../../locales/i18n';
import Helper from '../../modules/Helper';
import Item, { IAirportItem } from './AirportSelect/Item';
import AirportSelectSearchInput from './AirportSelectSearchInput';
import FadeTransition from './FadeTransition';

type AirportSelectProps = {
	openedInput?: string;
	name: string;
	enabledVert: string;
	placeholder?: string;
	mobileHeading?: string;
	icon: string;
	lng?: string;
	i18nValidationDropDownKey?: string;
	searchForZipCode?: boolean;
	preventSetInputBoxClassName?: boolean;
	selected?: IAirportItem;
	openInput: (name?: string) => void;
	togglePreventEnter: (toggle: boolean) => void;
	setTooltipError: (error: object) => void;
	togglePreventFocus: (toggle: boolean) => void;
	onSelect: (item: IAirportItem | object) => void;
	onChange?: (keyword: string) => void;
};
type AirportSelectState = {
	keyword: string;
	selected: string;
	list: IAirportItem[];
	cities: IAirportItem[];
	finalItems: IAirportItem[];
	in: boolean;
	enableInput: boolean;
	hovered: boolean;
	focusedIndex: number;
	isMobile: boolean;
};

class AirportSelect extends React.Component<AirportSelectProps, AirportSelectState> {
	constructor(props: AirportSelectProps) {
		super(props);
		this.state = {
			keyword: '',
			selected: '',
			list: [],
			cities: [],
			finalItems: [],
			in: false,
			enableInput: false,
			focusedIndex: -1,
			hovered: false,
			isMobile: false,
		};
		if (typeof window != 'undefined') {
			window.addEventListener('resize', this.checkWindowSize);
		}
	}

	checkWindowSize = () => {
		const width = window.innerWidth;
		this.setState({
			isMobile: width < 575,
		});
	};

	componentDidMount() {
		if (typeof window != 'undefined') {
			this.checkWindowSize();
		}
		document.addEventListener('keydown', this.handleKeyboardEvent);
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleKeyboardEvent);
		window.removeEventListener('resize', this.checkWindowSize);
	}

	handleKeyboardEvent = (e: KeyboardEvent) => {
		const isOpenedInput = this.props.openedInput === this.props.name;
		if (!isOpenedInput) return;
		switch (e.keyCode) {
			case 9:
				if (!e.shiftKey) {
					this.selectFocusedIndex(true);
				}
				break;
			case 13:
				e.preventDefault();
				this.enterEvent();
				break;
			case 38:
				e.preventDefault();
				this.pageUpEvent();
				break;
			case 40:
				e.preventDefault();
				this.pageDownEvent();
				break;
			default:
				break;
		}
	};

	enterEvent = () => {
		const { finalItems, focusedIndex } = this.state;
		if (focusedIndex === -1) return;
		this.props.togglePreventEnter(true);
		this.onSelect(finalItems[focusedIndex]);
	};

	pageUpEvent = () => {
		const { focusedIndex } = this.state;

		if (focusedIndex === -1) return;
		this.setState(
			{
				focusedIndex: focusedIndex - 1,
			},
			() => {}
		);
	};

	pageDownEvent = () => {
		const { finalItems, focusedIndex } = this.state;
		if (finalItems.length === 0) return;
		if (focusedIndex === this.state.finalItems.length - 1) return;
		this.setState(
			{
				focusedIndex: focusedIndex + 1,
			},
			() => {}
		);
	};

	onChange = (e: string) => {
		this.setState(
			{
				keyword: e,
			},
			() => {
				this.fetchAirports();
			}
		);
		this.props.onChange && this.props.onChange(e);
	};

	fetchAirports = () => {
		const { keyword } = this.state;

		if (keyword === '') {
			this.setState({
				list: [],
				cities: [],
				finalItems: [],
			});
			if (!this.state.isMobile) {
				this.props.openInput();
				// @ts-ignore
				this.onSelect({});
			}
		}

		if (keyword === '' || keyword.length < 2) {
			return this.setState({
				list: [],
				in: false,
			});
		}

		this.props.openInput(this.props.name);
		this.suggestKayak(keyword);
		return;
	};

	suggestKayak = (keyword: string) => {
		const vertURLs = {
			flights: `https://www.kayak.com/mvm/smartyv2/search?f=j&s=13&v=v1&lc=${
				this.props.lng
			}&lc_cc=${this.props.lng?.toUpperCase()}&where=`,
		};
		const config: AxiosRequestConfig = {
			method: 'get',
			// @ts-ignore
			url: vertURLs['flights'] + keyword,
		};

		axios(config)
			.then((response) => {
				const resultItems = response.data;

				// @ts-ignore
				const airports = resultItems
					.filter((item: { loctype: string }) => item.loctype === 'ap')
					.map((item: any) => {
						return {
							country: item.country,
							name: item.name,
							state: item.region,
							city: item.cityonly,
							iata: item.id,
							type: 'airport',
							kayakType: item.kayakType,
							ptid: item.ptid,
							stateCode: item.rc,
							countryCode: item.cc,
						};
					});
				this.setState(
					{
						list: airports,
						in: true,
					},
					() => {
						this.baseCheckItems();
					}
				);
			})
			.catch((error) => {
				console.log(error);
				this.doSuggestFlightsElastic(keyword);
			});
	};

	doSuggestFlightsElastic = (keyword: string) => {
		const requests = [];
		// @ts-ignore
		if (isNaN(keyword)) {
			requests.push(this.suggestAirports(keyword));
		} else {
			this.setState({
				list: [],
				in: true,
			});
		}
		axios.all(requests.map((item) => axios(item))).then((responses) => {
			let airports = [];
			airports = responses[0].data.results.map((item: any) => {
				return {
					country: item.country.raw,
					city: item.city.raw,
					iata: item.iata.raw,
					name: item.name.raw,
					allAirports: item.all_airports.raw === '1',
					state: item.state.raw,
					documentId: item._meta.id,
				};
			});
			this.setState(
				{
					list: airports,
					cities: [],
					in: true,
				},
				() => {
					this.baseCheckItems();
				}
			);
		});
	};

	suggestAirports = (keyword: string) => {
		const data = JSON.stringify({
			query: keyword,
			precision: 3,
			search_fields: {
				city: {},
				iata: {},
				country: {},
				state: {},
				name: {},
			},

			result_fields: {
				country: {
					raw: {},
				},
				city: {
					raw: {},
				},
				name: {
					raw: {},
				},
				iata: {
					raw: {},
				},
				all_airports: {
					raw: {},
				},
				state: {
					raw: {},
				},
			},
		});

		const config: AxiosRequestConfig = {
			method: 'post',
			url:
				process.env.NEXT_PUBLIC_ELASTIC_BASE_URL +
				'/engines/' +
				process.env.NEXT_PUBLIC_ELASTIC_AIRPORT_ENGINE_NAME +
				'/search',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + process.env.NEXT_PUBLIC_ELASTIC_AUTHORIZATION_TOKEN,
			},
			data,
		};
		return config;
	};
	sendClickRequest = (item: IAirportItem) => {
		const { keyword } = this.state;
		const data = {
			query: keyword, // user input
			document_id: item.documentId, // document that user selected  we get is from search endpoint result
			tags: ['flights', 'sf'],
		};
		const config: AxiosRequestConfig = {
			method: 'post',
			url:
				process.env.NEXT_PUBLIC_ELASTIC_BASE_URL +
				'/engines/' +
				process.env.NEXT_PUBLIC_ELASTIC_AIRPORT_ENGINE_NAME +
				'/click',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + process.env.NEXT_PUBLIC_ELASTIC_AUTHORIZATION_TOKEN,
			},
			data: JSON.stringify(data),
		};
		axios(config)
			.then(() => {})
			.catch(() => {});
	};

	onSelect = (item?: IAirportItem) => {
		if (item === undefined) return;
		this.setState(
			{
				in: false,
				enableInput: false, // const parsed = Helper.parseQueryString(window.location.search, true);
			},
			() => {
				this.props.onSelect(item);
			}
		);
	};

	getSelected = (selected: IAirportItem): string => {
		const node: HTMLElement | null = document.getElementById(this.props.name + '-input');
		if (!node) return '';
		const width = node.offsetWidth - 10;
		// @ts-ignore
		return Helper.getSelectedLocation(selected, width);
	};
	selectIndex = (index: number, preventFocus?: boolean) => {
		preventFocus = preventFocus || false;
		const listLength = this.state.list.length;
		const citiesLength = this.state.cities.length;
		if (listLength) {
			if (preventFocus) this.props.togglePreventFocus(true);
			this.onSelect(this.state.list[index]);
		} else if (citiesLength) {
			if (preventFocus) this.props.togglePreventFocus(true);
			this.onSelect(this.state.cities[index]);
		}
	};
	selectFirstItem = (preventFocus?: boolean) => {
		this.selectIndex(0, preventFocus);
	};
	selectFocusedIndex = (preventFocus?: boolean) => {
		if (this.state.focusedIndex === -1) return;
		this.selectIndex(this.state.focusedIndex, preventFocus);
	};

	UNSAFE_componentWillReceiveProps(nextProps: AirportSelectProps) {
		if (nextProps.selected) {
			if (!Helper.isEmpty(nextProps.selected)) {
				this.setState({
					selected: this.getSelected(nextProps.selected),
				});
			} else {
				this.setState(
					{
						selected: '',
					},
					() => {}
				);
			}
		}
		const name = this.props.name;
		const listLength = this.state.list.length;
		const citiesLength = this.state.cities.length;

		if (!nextProps.openedInput && this.props.openedInput === name && this.state.keyword) {
			if (!listLength && !citiesLength) {
				if (this.props.i18nValidationDropDownKey && i18n.t(this.props.i18nValidationDropDownKey)) {
					this.props.setTooltipError({
						[name]: i18n.t(this.props.i18nValidationDropDownKey),
					});
				}
			} else if (Helper.isEmpty(this.props.selected) && this.props.enabledVert === 'flights') {
				this.selectFirstItem();
			}
		}
	}

	checkItems = () => {
		const airports = Helper.chunk(this.state.list, 3);
		const cities = Helper.chunk(this.state.cities, 2);
		let countLoop = airports.length;
		if (cities.length > countLoop) countLoop = cities.length;
		const items: IAirportItem[] = [];
		for (let i = 0; i < countLoop; i++) {
			if (Array.isArray(airports[i])) {
				airports[i].forEach((airport: IAirportItem) => {
					items.push({ ...airport, type: 'airport' });
				});
			}
			if (Array.isArray(cities[i])) {
				cities[i].forEach((airport: IAirportItem) => {
					items.push({ ...airport, type: 'city' });
				});
			}
		}
		this.setState({
			finalItems: items,
		});
	};
	baseCheckItems = () => {
		switch (this.props.enabledVert) {
			case 'hotels':
				this.checkHotelItems();
				break;
			default:
				this.checkItems();
				break;
		}
	};
	checkHotelItems = () => {
		const airports = Helper.chunk(this.state.list, 4);
		const cities = Helper.chunk(this.state.cities, 4);
		let countLoop = airports.length;
		if (cities.length > countLoop) countLoop = cities.length;
		const items: IAirportItem[] = [];
		for (let i = 0; i < countLoop; i++) {
			if (Array.isArray(cities[i])) {
				cities[i].forEach((airport: IAirportItem) => {
					items.push({ ...airport, type: 'city' });
				});
			}
			if (Array.isArray(airports[i])) {
				airports[i].forEach((airport: IAirportItem) => {
					items.push({ ...airport, type: 'airport' });
				});
			}
		}
		this.setState({
			finalItems: items,
		});
	};
	focusInput = () => {
		this.props.openInput(this.props.name);

		setTimeout(() => {
			document.getElementById(this.props.name + '-input')?.blur();
			document.getElementById(this.props.name + '-input')?.focus();
		}, 1);
	};

	onHover = () => {
		this.setState({
			hovered: true,
			focusedIndex: -1,
		});
	};

	onHoverClose = () => {
		this.setState({
			hovered: false,
			focusedIndex: -1,
		});
	};

	renderMobileOverlayHeader = () => {
		return (
			<div className="mobile-overlay-header">
				<div className="p-2" onClick={() => this.props.openInput('')}>
					<ExitIcon />
				</div>
				<div className="name mx-4">{this.props.mobileHeading}</div>
			</div>
		);
	};

	render() {
		const { keyword, hovered, finalItems, focusedIndex, isMobile, selected } = this.state;
		const {
			openedInput,
			enabledVert,
			name,
			preventSetInputBoxClassName,
			placeholder,
			mobileHeading,
			icon: Icon,
		} = this.props;
		const { onSelect, onHover, onHoverClose } = this;
		const isOpenedInput = openedInput === name;
		const isOpenedMobileOverlay = isOpenedInput && isMobile;

		const items = [];

		for (let i = 0; i < finalItems.length; i++) {
			items.push(
				<Item
					focused={i === focusedIndex && !hovered}
					enabledVert={enabledVert}
					firstItem={finalItems[i].allAirports}
					key={i}
					onHover={onHover}
					onHoverClose={onHoverClose}
					onSelect={onSelect}
					keyword={keyword}
					type={finalItems[i].type}
					item={finalItems[i]}
				/>
			);
		}

		return (
			<div
				id={name}
				tabIndex={0}
				className={classnames('airport-select flex-1', {
					'open-input': isOpenedInput,
					'input-box': !preventSetInputBoxClassName,
					'show-mobile-back-overlay': isOpenedMobileOverlay,
				})}
			>
				{isOpenedMobileOverlay ? this.renderMobileOverlayHeader() : ''}
				<div
					onClick={this.focusInput}
					className={classnames('input-control', {
						focused: isOpenedInput,
						filled: !!selected,
					})}
				>
					<div className="input-icon d-flex">
						<Icon />
					</div>
					<AirportSelectSearchInput
						name={name}
						selected={selected}
						placeholder={isMobile ? mobileHeading : placeholder}
						onChange={debounce(this.onChange, 300)}
					/>
				</div>
				<FadeTransition timeout={500} in={Boolean(isOpenedInput && items.length)}>
					<div className="items-container">
						<div className="items-list">
							<div className="items">
								<CustomScroll scrollTo={focusedIndex > 4 ? (focusedIndex - 4) * 60 : 0}>
									<div style={{ maxHeight: '275px' }}>{items}</div>
								</CustomScroll>
							</div>
						</div>
					</div>
				</FadeTransition>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	openedInput: state.base.openedInput,
	enabledVert: state.base.enabledVert,
	lng: state.base.lng,
});

export default connect(mapStateToProps, {
	openInput,
	setTooltipError,
	togglePreventFocus,
	togglePreventEnter,
})(AirportSelect);
