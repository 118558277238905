import CalendarIcon from '@images/calendar-icon.svg';
import { DEFAULT_LOCALE } from 'app/modules/Constants';
import classnames from 'classnames';
import { addDays, isAfter, isDate } from 'date-fns';
import React from 'react';
import { connect } from 'react-redux';
import { openInput } from '../../../actions/baseActions';
import { selectFromDate, selectToDate } from '../../../actions/flightActions';
import DatePicker from '../Common/DatePicker';
import FadeTransition from '../Common/FadeTransition';
import { ItemType } from '../Common/InputDropDown';

type DatePickerFromProps = {
	selectedToDate: Date;
	selectedFromDate: Date;
	name: string;
	openedInput?: string;
	openedChildInput?: string;
	autoJump?: boolean;
	preventSetInputBoxClassName?: boolean;
	selectedTripType?: ItemType;
	lng: string;
	openInput: (name?: string | null) => void;
	selectToDate: (date: Date) => void;
	selectFromDate: (date: Date) => void;
};
type DatePickerFromState = {
	in: boolean;
	datepicker: boolean;
};

class DatePickerFrom extends React.Component<DatePickerFromProps, DatePickerFromState> {
	constructor(props: DatePickerFromProps) {
		super(props);
		this.state = {
			in: false,
			datepicker: false,
		};
	}

	onSelectDate = (from: Date, keyboardNavigation = false) => {
		let to = this.props.selectedToDate;
		if (!isDate(from)) {
			from = new Date();
		} else {
			from = new Date(from);
		}
		if (!isDate(to)) {
			to = addDays(from, 1);
		} else {
			to = new Date(to);
		}
		const fromDate = from.getTime();
		const toDate = to.getTime();
		if (isAfter(fromDate, toDate)) {
			to = new Date(from);
		}
		this.props.selectFromDate(from);
		this.props.selectToDate(to);
		if (keyboardNavigation) {
			return;
		}
		this.openCalendar(true);
	};

	openCalendar = (openToDateCalendar?: boolean) => {
		openToDateCalendar = openToDateCalendar || false;
		this.setState(
			{
				in: !this.state.in,
			},
			() => {
				if (this.state.in) {
					this.props.openInput(this.props.name);
				} else if (!openToDateCalendar || this.props.selectedTripType?.value === 'one-way') {
					this.props.openInput();
				} else if (openToDateCalendar) {
					if (this.props.autoJump) {
						this.props.openInput('date-to');
					} else {
						this.props.openInput();
					}
				}
			}
		);
	};

	componentDidUpdate(prevProps: DatePickerFromProps) {
		if (!this.props.openedInput && this.state.in) {
			this.setState({
				in: false,
				datepicker: false,
			});
		} else if (
			this.props.openedInput === this.props.name &&
			this.props.openedInput !== prevProps.openedInput &&
			!this.state.in
		) {
			this.setState({
				in: true,
			});
		}
	}

	render() {
		const { name, openedInput, preventSetInputBoxClassName, selectedFromDate, lng } = this.props;
		const locale = lng === 'en' ? DEFAULT_LOCALE : lng;

		return (
			<div
				id={name}
				className={classnames('flex-1', {
					'open-input': openedInput === name,
					'input-box': !preventSetInputBoxClassName,
				})}
			>
				<div
					className={classnames('input-control justify-content-between filled', {
						focused: openedInput === name,
					})}
					aria-label={selectedFromDate.toLocaleString(locale, {
						weekday: 'short',
						month: 'short',
						day: 'numeric',
					})}
				>
					<label htmlFor={name + '-input'}>
						<input type="text" className="hidden-input" id={name + '-input'} />
					</label>
					<div className="d-flex align-items-center flex-1" onClick={() => this.openCalendar()}>
						<div className="input-icon d-flex">
							<CalendarIcon />
						</div>

						<div className="selected">
							{selectedFromDate.toLocaleString(locale, {
								weekday: 'short',
								month: 'short',
								day: 'numeric',
							})}
						</div>
					</div>
				</div>
				<div style={{ position: 'relative' }}>
					<FadeTransition timeout={500} in={openedInput === name}>
						<DatePicker before={new Date()} selected={selectedFromDate as Date} onSelectDate={this.onSelectDate} />
					</FadeTransition>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	selectedFromDate: state.flight.selectedFromDate,
	selectedToDate: state.flight.selectedToDate,
	openedInput: state.base.openedInput,
	selectedTripType: state.flight.selectedTripType,
	autoJump: state.base.autoJump,
	lng: state.base.lng,
});

export default connect(mapStateToProps, { selectFromDate, openInput, selectToDate })(DatePickerFrom);
