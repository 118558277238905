import { PassengerOptions } from 'app/modules/Constants';
import classnames from 'classnames';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { openInput } from '../../../actions/baseActions';
import { setCountAdults, setCountChildren } from '../../../actions/flightActions';
import FadeTransition from '../Common/FadeTransition';
import Trans from '../Common/Trans';
import CounterItem from './Passengers/CounterItem';

type InputPassengersProps = {
	countAdults: number;
	countChildren: number;
	name: string;
	openedInput?: string;
	openInput: (name: string | null) => void;
	setCountAdults: (count: number) => void;
	setCountChildren: (count: number) => void;
};

const InputPassengers: FC<InputPassengersProps> = (props) => {
	const [fadeIn, setFadeIn] = useState(false);
	const { t } = useTranslation();

	const getSelected = () => {
		const { countAdults, countChildren } = props;
		if (countAdults && countChildren) {
			return countAdults + countChildren + ' ' + t('keywords.passengers');
		}
		return countAdults + ' ' + t('keywords.adults');
	};

	const showItemsList = () => {
		props.openInput(props.name);
		setFadeIn(!fadeIn);
	};

	const handleAdultCountChange = (count: number) => {
		props.setCountAdults(count);
	};

	const handleChildCountChange = (count: number) => {
		props.setCountChildren(count);
	};

	const close = () => {
		setFadeIn(false);
		props.openInput(null);
	};
	const clear = () => {
		props.setCountAdults(PassengerOptions['adults'].min);
		props.setCountChildren(PassengerOptions['children'].min);
	};

	const { countAdults, countChildren } = props;
	return (
		<div
			id={props.name}
			className={classnames('basic-drop-down input-basic-drop-down', {
				'open-input': props.openedInput === props.name,
			})}
		>
			<div onClick={showItemsList} className="input-control justify-content-between">
				<span className="d-name">{getSelected()}</span>
				<span style={{ marginRight: '10px' }}>
					<img width={30} height={30} src="/images/icons/caret-down.svg" alt="" />
				</span>
			</div>
			<FadeTransition timeout={1} in={props.openedInput === props.name}>
				<div className="body">
					<div className="box">
						<div className="name">
							<Trans i18nKey="keywords.selectPassengers" />
						</div>
						<div onClick={clear} className="clear-button">
							<span className="button">
								<Trans i18nKey="keywords.clear" />
							</span>
						</div>
					</div>
					<CounterItem onCountChange={handleAdultCountChange} count={countAdults} name={t('keywords.adults')} />
					<CounterItem onCountChange={handleChildCountChange} count={countChildren} name={t('keywords.children')} />
					<div onClick={close} className="box">
						<div
							className="name "
							style={{
								fontWeight: 'bold',
								color: '#049dd9',
							}}
						>
							<Trans i18nKey="keywords.done" />
						</div>
					</div>
				</div>
			</FadeTransition>
		</div>
	);
};

// @ts-ignore
const mapStateToProps = (state) => ({
	openedInput: state.base.openedInput,
	countAdults: state.flight.countAdults,
	countChildren: state.flight.countChildren,
});

export default connect(mapStateToProps, { openInput, setCountChildren, setCountAdults })(InputPassengers);
