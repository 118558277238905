import classnames from 'classnames';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { openInput } from '../../../../actions/baseActions';
import { setCountAdults, setCountChildren } from '../../../../actions/flightActions';
import FadeTransition from '../../Common/FadeTransition';
import CounterItem from '../../Flight/Passengers/CounterItem';

type PassengerCounterProps = {
	openedInput?: string | null;
	name: string;
	placeholder: string;
	countAdults: number;
	countChildren: number;
	openInput: (name: string | null) => void;
	setCountAdults: (count: number) => void;
	setCountChildren: (count: number) => void;
};
type PassengerCounterState = {
	in: boolean;
	adults: number;
	children: number;
};

const PassengerCounter: FC<PassengerCounterProps> = (props) => {
	const { t } = useTranslation();
	const [state, setState] = useState<PassengerCounterState>({
		in: false,
		adults: 1,
		children: 0,
	});

	const getSelected = () => {
		const { countAdults, countChildren } = props;
		if (countAdults && countChildren) {
			return `${countAdults + countChildren} ${t('keywords.passengers')}`;
		}
		return `${countAdults} ${t('keywords.adults')}`;
	};

	const showItemsList = () => {
		props.openInput(props.name);
		setState({ ...state, in: !state.in });
	};

	const handleAdultCountChange = (count: number) => {
		props.setCountAdults(count);
	};

	const handleChildCountChange = (count: number) => {
		props.setCountChildren(count);
	};

	const { countAdults, countChildren } = props;
	return (
		<div
			className={classnames('basic-drop-down', {
				'open-input': props.openedInput === props.name,
			})}
		>
			<div onClick={showItemsList}>
				<span className="d-name">{getSelected()}</span>
				<span className="d-icon">
					<i className="icon-arrow" />
				</span>
			</div>
			<FadeTransition timeout={1} in={props.openedInput === props.name}>
				<div className="body">
					<CounterItem onCountChange={handleAdultCountChange} count={countAdults} name={t('keywords.adults')} />
					<CounterItem onCountChange={handleChildCountChange} count={countChildren} name={t('keywords.children')} />
				</div>
			</FadeTransition>
		</div>
	);
};

// @ts-ignore
const mapStateToProps = (state) => ({
	openedInput: state.base.openedInput,
	countAdults: state.flight.countAdults,
	countChildren: state.flight.countChildren,
});

export default connect(mapStateToProps, { openInput, setCountChildren, setCountAdults })(PassengerCounter);
