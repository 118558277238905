import Trans from 'app/components/Common/Trans';
import TranslateString from 'app/components/Common/TranslateString';
import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { openInput } from '../../../actions/baseActions';
import TabBarSelect from './TabBarSelect';

type BasicDropDownProps = {
	placeholder: string;
	enableTabMode?: boolean;
	showOnSmallDevices?: boolean;
	showOnMediumDevices?: boolean;
	mainInput?: boolean;
	preventCloseInput?: boolean;
	openedInput?: string;
	icon?: string;
	customSelectedText?: string;
	showCustomSelectedText?: boolean;
	items: IItem[];
	selected?: IItem;
	name: string;
	testId?: string;
	onSelect: (item: IItem) => void;
	openInput: (name?: string | null) => void;
};
type BasicDropDownState = {
	selected?: IItem;
	in: boolean;
};

export interface IItem {
	label: string;
	value: string;
	icon?: string;
}

class BasicDropDown extends React.Component<BasicDropDownProps, BasicDropDownState> {
	constructor(props: BasicDropDownProps) {
		super(props);
		this.state = {
			selected: undefined,
			in: false,
		};
	}

	getSelected() {
		if (this.props.showCustomSelectedText) {
			return this.props.customSelectedText;
		}
		if (this.state.selected && Object.keys(this.state.selected).length) {
			return <TranslateString i18nKey={this.state.selected.label} />;
		}
		if (this.props.selected === undefined) {
			if (this.state.selected && Object.keys(this.state.selected).length) {
				return <TranslateString i18nKey={this.state.selected.label} />;
			}
			return this.props.placeholder;
		} else if (Object.keys(this.props.selected).length) {
			return <TranslateString i18nKey={this.props.selected.label} />;
		} else {
			return <TranslateString i18nKey={this.props.placeholder} />;
		}
	}

	showItemsList = () => {
		this.setState(
			{
				in: !this.state.in,
			},
			() => {
				if (this.state.in) {
					this.props.openInput(this.props.name);
				} else {
					this.close();
				}
			}
		);
	};
	_onSelect = (item: IItem) => {
		this.setState(
			{
				selected: item,
				in: false,
			},
			() => {
				this.close();
				if (this.props.onSelect) {
					this.props.onSelect(item);
				}
			}
		);
	};
	getSelectedItem = () => {
		if (this.state.selected && Object.keys(this.state.selected).length) {
			return this.state.selected;
		}
		if (this.props.selected && Object.keys(this.props.selected)) {
			return this.props.selected;
		}
		return undefined;
	};

	// @ts-ignore
	// eslint-disable-next-line react/no-deprecated
	componentWillReceiveProps(nextProps: BasicDropDownProps) {
		if (
			nextProps.openedInput !== this.props.openedInput &&
			nextProps.openedInput &&
			nextProps.openedInput !== this.props.name
		) {
			this.setState({
				in: false,
			});
		}
	}

	close = () => {
		this.setState(
			{
				in: false,
			},
			() => {
				if (this.props.preventCloseInput) {
					return;
				}
				this.props.openInput(null);
			}
		);
	};

	render() {
		const selectedItem = this.getSelectedItem();
		const { items } = this.props;

		return (
			<>
				<div
					className={classnames('basic-drop-down', {
						'open-input': this.props.openedInput === this.props.name,
						'main-input': this.props.mainInput,
						'd-none d-xl-block': !this.props.showOnSmallDevices,
					})}
					data-testid={this.props.testId}
				>
					<div
						className={classnames({
							info: this.props.mainInput,
						})}
						onClick={this.showItemsList}
					>
						<span className="d-name">
							{this.props.icon ? (
								<span>
									<i className={this.props.icon} />
								</span>
							) : null}
							<span> {this.getSelected()}</span>
						</span>
						<span className="d-icon">
							<i className="icon-arrow" />
						</span>
					</div>
					<CSSTransition
						in={this.props.openedInput === this.props.name}
						timeout={1}
						unmountOnExit={true}
						classNames="fade"
					>
						<div
							className={classnames('body', {
								'without-max-width': this.props.mainInput,
							})}
						>
							{items.map((item, key) => {
								return (
									<div
										onClick={() => this._onSelect(item)}
										key={key}
										className={classnames('item', {
											selected: !!(
												selectedItem &&
												Object.keys(selectedItem).length &&
												selectedItem.value === item.value
											),
										})}
									>
										{item.icon ? <i className={item.icon} /> : null}
										{<Trans i18nKey={item.label} />}
									</div>
								);
							})}
						</div>
					</CSSTransition>
				</div>
				{this.props.enableTabMode ? (
					<TabBarSelect onSelect={this._onSelect} items={items} selectedItem={selectedItem} />
				) : (
					''
				)}
			</>
		);
	}
}

// @ts-ignore
const mapStateToProps = (state) => ({
	openedInput: state.base.openedInput,
});

export default connect(mapStateToProps, { openInput })(BasicDropDown);
