import Helper from 'app/modules/Helper';
import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { openInput } from '../../../actions/baseActions';

type AirportSelectSearchInputProps = {
	selected: string;
	name: string;
	placeholder?: string;
	onChange: (keyword: string) => void;
	openInput: (name?: string) => void;
};
type AirportSelectSearchInputState = {
	keyword: string;
};

class AirportSelectSearchInput extends React.Component<AirportSelectSearchInputProps, AirportSelectSearchInputState> {
	constructor(props: AirportSelectSearchInputProps) {
		super(props);
		this.state = {
			keyword: '',
		};
	}

	handleClick = (ev: MouseEvent) => {
		const el: EventTarget | null = ev.currentTarget;
		if (document.activeElement !== el && el) {
			setTimeout(() => {
				// @ts-ignore
				el.select();
			}, 0);
		}
		if (Helper.isMobile()) {
			setTimeout(() => {
				window.scrollTo(0, 0);
			}, 250);
		}
	};
	onChange = (e: ChangeEvent<HTMLInputElement>) => {
		this.setState(
			{
				keyword: e.target.value,
			},
			() => {
				this.props.onChange(this.state.keyword);
			}
		);
	};

	componentDidUpdate(prevProps: AirportSelectSearchInputProps) {
		const { selected: newSelected } = this.props;
		const { selected: oldSelected } = prevProps;
		if (newSelected !== oldSelected) {
			this.setState({
				keyword: newSelected || '',
			});
		}
	}

	render() {
		const { placeholder, name } = this.props;
		const keyword = this.state.keyword;
		return (
			<input
				role="textbox"
				onClick={() => this.props.openInput(name)}
				id={`${name}-input`}
				name={`${name}-input`}
				data-hj-allow
				autoComplete="off"
				// @ts-ignore
				onMouseDown={this.handleClick}
				onChange={this.onChange}
				placeholder={placeholder}
				value={keyword !== null ? keyword : ''}
				type="text"
				className="form-control"
			/>
		);
	}
}

export default connect(null, { openInput })(AirportSelectSearchInput);
