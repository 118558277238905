import classNames from 'classnames';
import { ChangeEvent, FC } from 'react';

type CheckboxProps = {
	id: string;
	label?: string;
	checked: boolean;
	disabled?: boolean;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const Checkbox: FC<CheckboxProps> = ({ id, label, checked, disabled = false, onChange }) => {
	return (
		<div className="form-check custom-checkbox position-relative">
			<label
				htmlFor={id}
				className={classNames('form-check-label', {
					disabled,
				})}
			>
				<input
					checked={checked}
					name={id}
					id={id}
					onChange={onChange}
					type="checkbox"
					className="form-check-input"
					value=""
					disabled={disabled}
				/>
				<span className="checkmark" /> {label}
			</label>
		</div>
	);
};

export default Checkbox;
